import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './styles/slideshow.css';
import codeText from './codeText'; // インポート

const Slideshow = () => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleLines, setVisibleLines] = useState([]);
  const [textComplete, setTextComplete] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [loading, setLoading] = useState(true); // ロード状態の管理
  const [loadingPercentage, setLoadingPercentage] = useState(0); // パーセンテージの状態を管理

  const handleImageLoad = () => {
    setShowImage(true);
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://kaitoportfolio.microcms.io/api/v1/portfolio', {
          headers: {
            'X-API-KEY': 'D47K5TpPZfRzZCyXPPs2YxML9X5uOJyWAQdZ'
          }
        });
        setImages(response.data.contents);
      } catch (error) {
        console.error('Error fetching data from MicroCMS:', error);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    const lines = codeText.split('\n');
    let currentLine = 0;

    const interval = setInterval(() => {
      setVisibleLines(prevLines => [...prevLines, lines[currentLine]]);
      currentLine += 1;

      setLoadingPercentage(Math.floor((currentLine / lines.length) * 100)); // パーセンテージを更新

      if (currentLine >= lines.length) {
        clearInterval(interval);
        setTextComplete(true);
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (textComplete && images.length > 0) {
      setShowImage(true);
      setLoading(false); // テキストと画像が準備できたらロード完了
      const interval = setInterval(() => {
        setShowImage(false);
        setTimeout(() => {
          setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
          setShowImage(true);
        }, 500);
      }, 2000000);

      return () => clearInterval(interval); // `interval` をクリア
    }
  }, [textComplete, images.length]);

  useEffect(() => {
    if (!loading) {
      const timeout = setTimeout(() => {
        const container = document.querySelector('.imgContainer');
        const loadingElement = document.querySelector('.loading');
        if (container) {
          container.classList.add('visible');
        }
        if (loadingElement) {
          loadingElement.classList.add('hidden'); // loading要素を非表示にする
        }
      }, 2000); // 2秒の待機時間を追加

      return () => clearTimeout(timeout); // `timeout` をクリア
    }
  }, [loading]);

  return (
    <>
      <div className="loading">
        <div className="loadingContent">
            KAITO_MATSUDA<br />
            PORTFOLIO_SITE<br />
            {loadingPercentage}%
          </div>
      </div>
      <div className="imgContainer">
        <div className="LeftArea">
          <div className="codeText">
            {visibleLines.map((line, index) => (
              <h3 key={index} className="line">{line}</h3>
            ))}
          </div>

        </div>

        <div className="RightArea">
          <div className="Area3">
            {textComplete && (
              <img
                src={images[currentIndex].src.url}
                alt={images[currentIndex].title}
                className={`imgArea ${showImage ? 'show' : ''}`}
                onLoad={handleImageLoad}
              />
            )}
          </div>
          <div className="Area4">
            <h3>News</h3>
            <h1>ポートフォリオサイトをオープンしました</h1>

            <p>2024 / 08 /26</p>
               
            <p className='day'>
            </p>

            <p>
              ポートフォリオサイトをオープンしました。インスタグラムのアーカイブなども載せているのでぜひ。
            </p>
            <div className="snsArea">

              <a href="https://www.instagram.com/kaito_matsuda_">
              Instagram
            </a>

            <a href="https://x.com/um1h1t0">
              Twitter
            </a>  
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Slideshow;
